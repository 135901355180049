<template>
  <b-card
    v-if="$can('update', 'users')"
    no-body
  >
    <b-card-header class="pb-50">
      <h5>
        Authorized Client Sites
      </h5>
    </b-card-header>
    <b-card-body>
      <!-- Per Page -->
      <b-row class="mb-2 mt-1">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
          <b-button
            class="ml-2"
            variant="primary"
            :disabled="this.$store.state.clients.fetchingClientSitesList"
            @click="refreshClientSitesList"
          >
            <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="selectAllRows"
          >
            <feather-icon icon="CheckSquareIcon" /><span class="text-nowrap ml-1">Select All</span>
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="clearSelected"
          >
            <feather-icon icon="MinusSquareIcon" /><span class="text-nowrap ml-1">Clear</span>
          </b-button>
        </b-col>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refClientSitesListTable"
            selectable
            hover
            responsive
            show-empty
            empty-text=""
            :items="fetchClientSites"
            :fields="tableColumns"
            primary-key="sitename"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy.sync="isBusy"
            @row-selected="onRowSelected"
          >
            <!-- Column: Site Name-->
            <template #cell(sitename)="data">
              <div class="text-nowrap">
                {{ data.item.sitename }}
              </div>
            </template>

            <!-- Column: province -->
            <template #cell(client_display_name)="data">
              <div class="text-nowrap">
                {{ data.item.client_display_name }}
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveClientSitesStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- Pagination -->
      <b-row class="mb-2 mt-1">
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalClientSites"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
      <!-- Update Button -->
      <b-row class="mt-3 mb-1">
        <b-col
          md="2"
        >
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            block
            @click="submitAuthClients"
          >
            Update Authorized Client Sites
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BPagination, BFormInput, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BTable, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store/index'
// import { watch } from '@vue/composition-api'
import useClientSitesList from './useClientSitesList'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BPagination,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BTable,
    BBadge,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    clientSitesListData: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      perPage: 25,
      perPageOptions: [25, 50, 100, 200],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    isFetchingClientList() {
      console.log('isFetchingClientList')
      console.log(this.$store.getters.getFetchingClientSitesList)
      return this.$store.getters.getFetchingClientSitesList
    },
  },
  setup() {
    const statusOptions = store.state.appSettings.is_active

    const {
      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalClientSites,
      clientSitesList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientSitesListTable,
      refetchData,

      // UI
      resolveClientSitesStatusVariant,

      // Extra Filters
      statusFilter,
    } = useClientSitesList()

    // const updateClientSitesListData = e => {
    //   console.log('updateClientSitesListData')
    //   console.log(e)
    //   e.forEach(item => {
    //     props.clientSitesListData.push(item)
    //   })
    // }

    // watch(clientSitesList, updateClientSitesListData)

    return {

      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalClientSites,
      clientSitesList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientSitesListTable,
      refetchData,

      // UI
      resolveClientSitesStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    }
  },
  watch: {
    clientSitesList() {
      console.log('Watch saw clientSitesList change')
      setTimeout(() => {
        this.highlightAuthClients()
      }, 500)
    },
  },
  methods: {
    refreshClientSitesList() {
      const prevAuthorizedClients = JSON.parse(JSON.stringify(this.userData.authorized_clients))
      this.$store.state.clients.fetchingClientSitesList = true
      this.refetchData().then(
        this.userData.authorized_clients = prevAuthorizedClients,
        this.highlightAuthClients(),
      )
    },
    onRowSelected(items) {
      console.log('onRowSelected')
      console.log(items)
      const tmp = []
      items.forEach(item => {
        tmp.push({ clientname: item.clientname, sitename: item.sitename })
      })
      console.log(tmp)
      this.userData.authorized_clients = JSON.parse(JSON.stringify(tmp))
      console.log(this.userData.authorized_clients)
    },
    selectAllRows() {
      console.log('selectAllRows')
      this.$refs.refClientSitesListTable.selectAllRows()
    },
    clearSelected() {
      console.log('clearSelected')
      this.$refs.refClientSitesListTable.clearSelected()
      this.userData.authorized_clients = []
    },
    highlightAuthClients() {
      console.log('highlightAuthClients')
      console.log(this.userData.authorized_clients)
      // Highlight the original Authorized Clients
      if (typeof this.userData.authorized_clients !== 'undefined' && this.userData.authorized_clients.length > 0) {
        this.userData.authorized_clients.forEach(item => {
          console.log(item)
          this.clientSitesList.forEach((site, index) => {
            console.log(site)
            if (item.customername === site.customername && item.sitename === site.sitename) {
              this.selectRow(index)
            }
          })
        })
      }
    },
    selectRow(rowNum) {
      console.log(`selectRow ${rowNum}`)
      this.$refs.refClientSitesListTable.selectRow(rowNum)
    },
    submitAuthClients() {
      if (ability.can('update', 'users')) {
        console.log(this.userData)
        // Create a clean dataset with only the data being updated
        const cleanUserData = {
          username: this.userData.username,
          authorized_clients: this.userData.authorized_clients,
          client_name: this.userData.client_name,
        }
        console.log(cleanUserData)
        // Call backend
        store.dispatch('app-user/updateUser', cleanUserData)
          .then(response => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'User Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating user information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
  .b-table-row-selected > td {
    background-color: #f8e2ca !important;
  }
</style>
