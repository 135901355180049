<template>
  <div v-if="userData && $can('update', 'users')">
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Users
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/users/list">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  to="/apps/users/list"
                >
                  Users
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  :to="'/apps/users/view/'+userData.username"
                >
                  {{ userData.username }}
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  Edit User
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <component :is="userData === undefined ? 'div' : 'b-card'">

      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="userData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching user data
        </h4>
        <div class="alert-body">
          No user found with this username.
        </div>
      </b-alert>

      <user-edit-tab-account
        :user-data="userData"
        :client-name-prop="clientNameProp"
        class="mt-2 pt-75"
      />
    </component>
    <!-- User Permissions Card -->
    <b-row>
      <b-col
        cols="24"
        xl="12"
        lg="12"
        md="12"
      >
        <user-edit-user-permissions-card :user-data="userData" />
      </b-col>
    </b-row>
    <!-- Client Permissions Card -->
    <b-row v-if="userData.cognito_group === 'Guard' || userData.cognito_group === 'Supervisor'">
      <b-col
        cols="24"
        xl="12"
        lg="12"
        md="12"
      >
        <user-edit-client-permissions-card :user-data="userData" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard, BAlert, BLink, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'

import { API } from 'aws-amplify'

import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import abilitiesTemplatesStoreModule from '../../abilitiestemplates/abilitiesTemplatesStoreModule'
import userStoreModule from '../userStoreModule'
import clientStoreModule from '../../clients/clientStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditUserPermissionsCard from './UserEditUserPermissionsCard.vue'
import UserEditClientPermissionsCard from './UserEditClientPermissionsCard.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,

    UserEditTabAccount,
    UserEditUserPermissionsCard,
    UserEditClientPermissionsCard,
  },
  props: {
    clientNameProp: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const tmp = JSON.parse(localStorage.getItem('abilities_templates'))
    if (tmp === null) {
      const TEMPLATE_APP_STORE_MODULE_NAME = 'app-abilities-templates'

      // Register module
      if (!store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(TEMPLATE_APP_STORE_MODULE_NAME, abilitiesTemplatesStoreModule)

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(TEMPLATE_APP_STORE_MODULE_NAME)
      })
    }

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    console.log(router.currentRoute.params.username)
    if (ability.can('read', 'users')) {
      const apiName = 'APIGateway'
      const path = `/user/${router.currentRoute.params.username}`
      // const path = '/user/'
      const myInit = { response: true }

      API.get(apiName, path, myInit)
        .then(response => {
          userData.value = response.data
          // eslint-disable-next-line no-param-reassign
          userData.value.fullName = `${userData.value.first_name} ${userData.value.last_name}`
          // eslint-disable-next-line no-param-reassign
          userData.value.role = `${userData.value.cognito_group}`
          userData.value.status = userData.value.is_active
          console.log('userData')
          console.log(userData)
        })
        .catch(error => {
          console.log(error)
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            userData.value = undefined
          }
        })
    }

    return {
      userData,
    }
  },
}
</script>

<style>

</style>
