<template>
  <div v-if="$can('update', 'users')">
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <image-uploader
          :class-name="'fileinput'"
          :debug="2"
          :max-width="100"
          :max-height="100"
          :quality="0.8"
          output-format="string"
          :preview="false"
          :capture="false"
          accept=".jpg"
          @input="inputAvatar"
        >
          <label
            slot="upload-label"
            for="fileInput"
          >
            <b-avatar
              ref="previewEl"
              :src="theAvatar"
              :text="avatarText(userData.fullName)"
              :variant="`light-${resolveUserRoleVariant(userData.role)}`"
              size="90px"
              rounded
            />
          </label>
        </image-uploader>
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>
      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer
      ref="observer"
      #default="{ handleSubmit }"
    >
      <b-form
        @submit.prevent="handleSubmit(validateForm)"
      >
        <b-row>
          <b-col
            md="6"
          >
            <!-- Field: Username -->
            <b-form-group
              label="Username"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="userData.username"
                  :state="errors.length > 0 ? false:null"
                  :readonly="true"
                  name="username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Client Name -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Client Name"
              label-for="client_display_name"
            >
              <validation-provider
                #default="{ errors }"
                name="client_display_name"
                rules="required"
              >
                <b-form-input
                  id="client_display_name"
                  v-model="userData.client_display_name"
                  :readonly="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                  :readonly="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
          >
            <!-- first name -->
            <b-form-group
              label="First Name"
              label-for="first_name"
            >
              <validation-provider
                #default="{ errors }"
                name="first_name"
                rules="required"
              >
                <b-form-input
                  id="first_name"
                  v-model="userData.first_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Last Name -->
          <b-col
            md="6"
          >
            <b-form-group
              label="LastName"
              label-for="last_name"
            >
              <validation-provider
                #default="{ errors }"
                name="last_name"
                rules="required"
              >
                <b-form-input
                  id="last_name"
                  v-model="userData.last_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Phone -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                :size="10"
              >
                <b-form-input
                  id="phone"
                  v-model="userData.phone"
                  placeholder="optional"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Field: Role -->
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="User Role"
              label-for="cognito_group"
            >
              <validation-provider
                #default="{ errors }"
                name="cognito_group"
                rules="required"
              >
                <v-select
                  v-model="userData.cognito_group"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="cognito_group"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Region -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Region"
              label-for="region_loc"
            >
              <validation-provider
                #default="{ errors }"
                name="region_loc"
                rules="required"
              >
                <v-select
                  v-model="userData.region_loc"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="region_loc"
                  rules="required"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="userData.group === 'Management'"
          class="mt-2 mb-1 border-bottom-primary"
        >
          <b-col>
            <h4>Security Services Information</h4>
          </b-col>
        </b-row>
        <!-- Field: Force Onboarding -->
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="Force Onboarding"
              label-for="is_onboarding"
            >
              <validation-provider
                #default="{ errors }"
                name="is_onboarding"
                rules="required"
              >
                <v-select
                  v-model="userData.is_onboarding"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="onboardingOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="is_onboarding"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <!-- Field: Options -->
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="User Duties"
              label-for="user_duties"
            >
              <validation-provider
                #default="{ errors }"
                name="duties"
                rules="required"
              >
                <b-form-checkbox
                  v-model="userData.duties.security_scheduling"
                  class="mb-1"
                  name="check-button"
                  switch
                  inline
                >
                  <h5>
                    Include user in Security Scheduling
                  </h5>
                </b-form-checkbox>
                <br>
                <b-form-checkbox
                  v-model="userData.duties.solutions_installer"
                  class="mb-1"
                  name="check-button"
                  switch
                  inline
                >
                  <h5>
                    Include user as Solutions Installer
                  </h5>
                </b-form-checkbox>
                <br>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row>
          <!-- Field: Status -->
          <b-col
            md="6"
          >
            <b-form-group
              label="Status"
              label-for="is_active"
            >
              <validation-provider
                #default="{ errors }"
                name="is_active"
                rules="required"
              >
                <v-select
                  v-model="userData.is_active"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="is_active"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="2"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
          <b-col
            md="2"
          >
            <!-- Action Buttons -->
            <b-button
              v-if="$can('delete', 'users')"
              variant="danger"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              @click="deleteUser"
            >
              Delete User
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Storage, Auth } from 'aws-amplify'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { required, email } from '@validations'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ImageUploader from 'vue-image-upload-resize'
import useUsersList from '../users-list/useUsersList'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
  },
  props: {
    userData: {
      type: Object,
      default: null,
    },
    clientNameProp: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // validation rules
      required,
      email,
      clients: [],
      clientsList: [],
      avatarImageUpload: {},
    }
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = store.state.appSettings.cognito_group

    const regionOptions = store.state.appSettings.regions_loc

    const statusOptions = store.state.appSettings.is_active

    const onboardingOptions = store.state.appSettings.is_onboarding

    const cellProviderOptions = store.state.appSettings.cell_phone_carrier
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const theAvatar = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      regionOptions,
      statusOptions,
      onboardingOptions,
      cellProviderOptions,
      refInputEl,
      previewEl,
      inputImageRenderer,
      theAvatar,
    }
  },
  created() {
    if (this.clientNameProp !== '') {
      this.userData.client_name = this.clientNameProp
    }

    if (typeof this.userData.region_loc === 'undefined') {
      this.userData.region_loc = 'All'
    }

    if (typeof this.userData.duties === 'undefined') {
      this.userData.duties = {
        security_scheduling: false, solutions_installer: false,
      }
    }

    this.clientsList = []

    this.clients = JSON.parse(localStorage.getItem('clients'))
    this.clients.forEach(item => {
      this.clientsList.push({ label: item.display_name, value: item.clientname })
    })
    console.log('clientsList')
    console.log(this.clientsList)
  },
  methods: {
    isNumeric(str) {
      if (typeof str !== 'string') return false // we only process strings!
      return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            && !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    validateForm() {
      if (ability.can('update', 'users')) {
        // Temporary Add: Adding additional fields to data set that will be included in for at later date
        this.userData.is_onboarding = false
        let proceedWithSave = true
        if (this.userData.phone !== undefined && this.userData.phone !== '' && this.userData.phone !== null) {
          this.userData.phone = this.userData.phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '')
          if (Number.isNaN(Number(this.userData.phone)) || this.userData.phone.length !== 10) {
            proceedWithSave = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Phone Number Validation Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'The phone number must be numbers only and 10 digits in length.',
              },
            })
          }
        }
        if (proceedWithSave) {
          this.$refs.observer.validate().then(success => {
            this.userData.fullName = `${this.userData.first_name} ${this.userData.last_name}`
            if (success) {
              console.log('form validated')
              console.log(this.userData)
              // Create a clean dataset with only the data being updated
              const cleanUserData = JSON.parse(JSON.stringify(this.userData))
              delete cleanUserData.abilities
              delete cleanUserData.authorized_clients
              console.log(cleanUserData)
              // Call backend
              store.dispatch('app-user/updateUser', cleanUserData)
                .then(response => {
                  console.log(response)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'User Updated',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response.data.errors !== 'undefined') {
                    error.response.data.errors.forEach(item => {
                      theErrorStr += `${item.message}\n`
                    })
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error updating user information.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
        }
      }
    },
    deleteUser() {
      if (ability.can('delete', 'users')) {
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete ${this.userData.first_name} ${this.userData.last_name}'s user account.`, {
            title: 'Delete User Account',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const qvars = {
                username: this.userData.username,
                clientname: this.userData.client_name,
              }
              store
                .dispatch('app-user/deleteUser', qvars)
                .then(response => {
                  console.log(response)
                  // Route user
                  const nextRout = { name: 'apps-users-list' }
                  this.$router.replace(nextRout)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'User Account deleted successfully.',
                          icon: 'CheckSquareIcon',
                          variant: 'success',
                        },
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error deleting User Account',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
    inputAvatar(imgFile) {
      console.log('inputAvatar')
      console.log(imgFile)
      this.uploadAvatar(imgFile)
    },
    uploadAvatar(imgFile) {
      console.log(this.firmwareData)
      if (ability.can('update', 'users')) {
        const filename = `useravatars/${this.userData.username}.jpg`

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Avatar is being uploaded. Please do not leave this screen.',
            icon: 'FilePlusIcon',
            variant: 'success',
          },
        })

        Storage.put(filename, imgFile, {
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: process.env.VUE_APP_AMPLIFY_STORAGE_AWSS3_IDENTITYPOOLID,
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
          .then(result => {
            console.log(result)
            this.userData.avatar = filename
            this.theAvatar = imgFile
          })
          .catch(err => {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Uploading Avatar Image.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
#fileInput {
  display: none;
}
</style>
